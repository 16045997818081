import { useEffect, useState } from 'react';
import User from './User';

import classes from './Users.module.css';

// const DUMMY_USERS = [
//   { time: 'time', _id: 'u1', fname: 'Max', lname: 'Max' },
//   { time: 'time', _id: 'u2', fname: 'Manuel', lname: 'Max' },
//   { time: 'time', _id: 'u3', fname: 'Julie', lname: 'Max' },
// ];

// let url1 = 'http://localhost:3001/vips'
// let url2 = 'http://localhost:3001/petitions'
let url1 = 'https://donotfarmoctopus.herokuapp.com/vips'
let url2 = 'https://donotfarmoctopus.herokuapp.com/petitions'


function Captialize(str) {
  let result = str.charAt(0).toUpperCase() + str.slice(1)
  return result
}



const Users = () => {
  // const [showUsers, setShowUsers] = useState(true);
  const [users, setUsers] = useState([])

  useEffect(() => {
    async function fetchUsersHandler() {
      const response1 = await fetch(url1)
      const response2 = await fetch(url2)
      // const data1 = await response.json()
      // const data2 = await response.json()
      // console.log(data1)
      const data1 = await response1.json()
      const data2 = await response2.json()
      const data = data1.concat(data2)
      // console.log(data)

      let i = 1;
      const loadedUsers = []
      for (const key in data) {
        loadedUsers.push({
          id: data[key]._id,
          number: i,
          time: data[key].time,
          fname: data[key].fname.toUpperCase(),
          lname: data[key].lname.toUpperCase(),
          institution: data[key].institution
        })
        i++
      }
      setUsers(loadedUsers)
    }

    fetchUsersHandler()

  }, [])


  // const toggleUsersHandler = () => {
  //   setShowUsers((curState) => !curState);
  // };

  const usersList = (
    <div>
      {users.map((user) => (
        <User key={user.id} id={user.id} number={user.number} time={user.time} fname={user.fname} lname={user.lname} institution={user.institution} />
      ))}
    </div>
  );

  return (
    <div className={classes.users}>
      <h2 className={classes.head_text}>SIGNATORIES</h2>
      <div className={classes.intro}>*Original signatory to the open letter <a href='https://www.wellbeingintlstudiesrepository.org/animsent/vol4/iss26/19/' target="_Blank">The octopus mind and the argument against farming it</a> in Animal Sentience in 2019. 

      </div>
      {/* <h1 className={classes.head_number}>{users.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h1> */}
      {/* <hr></hr> */}
      {/* <button onClick={toggleUsersHandler}>
        {showUsers ? 'Hide' : 'Show'} Users
      </button>
      {showUsers && usersList} */}
      {usersList}
    </div>
  );
};

export default Users;
