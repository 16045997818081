import Users from './components/Users';

function App() {
  return (
    <div>
      <Users />
    </div>
  );
}

export default App;
