import { Fragment } from 'react';
import classes from './User.module.css';

const User = (props) => {
  return (
    <Fragment>
      <div className={classes.user}>
        <div className={classes.content}>
          <div className={classes.number}>{props.number + '.'}</div>
          {/* <div>{props.id}</div> */}
          {/* <div>{props.fname}<div/> */}
          <div className={classes.sign_name}>{props.time + ", "}{props.fname} {props.lname + ","} {props.institution}</div>
        </div>
      </div>
      {/* <hr></hr> */}
    </Fragment>
  )

}

export default User;
